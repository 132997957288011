(function($) {

	$.fn.initNews = function() {

		var articles = this.find(".article");

		articles.addClass("disable-animation").addClass("hidden").waypoint(function(direction) {

			$(this.element).removeClass("hidden");

		}, {
			offset: function() {
				return (window.innerHeight - $(this.element).height() / 2);
			}
		});

		window.setTimeout(function() {
			articles.removeClass("disable-animation");
		}, 300);

		return this;

	}

})(jQuery);
