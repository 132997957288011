$(function() {
	$('a.smooth-scroll').click(function() {
		var $this = $(this);
		var href = $this.attr("href");
		if (href.match(/\#/)) {
			if (href.indexOf('#') != 0) {
				href = href.substr(href.indexOf('#'));
			}
			var $target = $(href);
			if ($target.length == 1) {
				$(window).scrollTo($target, 500);
				return false;
			}
		}
	});
})
