(function() {

	$.fn.initHero = function(mapId) {
		$(this).find(".city-boxes a.city").click(function() {
			var cityId = $(this).attr("href").substr(1);

			var offset = 0;

			var wh = window.innerHeight;
			var mh = $("#"+mapId).height();

			if (wh > mh) {
				offset = (wh - mh) / 2 * -1;
			}

			$(window).scrollTo('#' + mapId, 500, {
				offset: offset
			});

			window.setTimeout(function() {
				goToCityMap(cityId)
			}, 500);

			return false;
		});
	};

})();
