(function($) {

	$.fn.magicLine = function(options) {

		if (!options.target) {
			console.log("Magic line init failed - missing 'target' parameter.");
			return this;
		}

		var $magicLine = $(options.target);

		if ($magicLine.length != 1) {
			console.log("Magic line init failed - 'target' parameter must match exactly 1 element.");
			return this;
		}

		options = $.extend({
			"hiddenClass": "",
			"visibleClass": "",
			"default": null,
			"outerWidth": true
		}, options);

		var $defaultElement = options.default ? ($(options.default)) : null;
		if (!$defaultElement.length) {
			$defaultElement = null;
		}

		var $hoveredElement = $defaultElement;


		// Handler

		$(this).each(function() {

			var $a = $(this);

			$a.hover(function() {

				if (!isVisible) {
					disableAnimation();
					moveLineTo($a);
					window.setTimeout(function() {
						disableAnimation(true);
						showLine();
					}, 25);
				} else {
					moveLineTo($a);
				}
				$hoveredElement = $a;

			}, function() {

				$hoveredElement = null;

				if ($defaultElement) {
					moveLineTo($defaultElement);
				} else {
					window.setTimeout(function() {
						if (!$hoveredElement) {
							hideLine();
						}
					}, 50);
				}

			});

		});

		// Initial state

		var originalTransition = $magicLine.css("transition");
		var isVisible = false;

		if ($defaultElement) {
			moveLineTo($defaultElement);
			showLine();
		} else {
			hideLine();
		}



		function moveLineTo(targetElement) {
			var $target = $(targetElement);
			var width = options.outerWidth ? $target.outerWidth() : $target.width();

			$magicLine.width(width);
			$magicLine.css("left", $target.position().left + "px");

		}

		function hideLine() {
			$magicLine
				.removeClass(options.visibleClass)
				.addClass(options.hiddenClass)
			;

			isVisible = false;
		}

		function showLine() {
			$magicLine
				.addClass(options.visibleClass)
				.removeClass(options.hiddenClass)
			;

			isVisible = true;
		}

		function disableAnimation(enable) {
			if (enable) {
				$magicLine.css("transition", originalTransition);
			} else {
				$magicLine.css("transition", "none");
			}
		}

		return this;

	}

})(jQuery);
