(function($) {

	$.fn.scrollArrow = function() {

		this.each(function() {

			var $this = $(this);
			var $target = $this.attr("href");
			var offset = parseFloat($this.attr("data-offset-percent")) || 0;

			$this.click(function() {

				var currentOffset = offset ? Math.round(window.innerHeight * offset / 100) : 0;

				$(window).scrollTo(
					$target,
					400,
					{
						offset: currentOffset
					}
				);

				return false;
			});

		})


		return this;

	};

})(jQuery);
