(function() {

	var wasInited = false;

	function initClankyFlexbox() {
		if (wasInited) {
			return;
		}

		$(function() {
			$(".news.page-news .box").append('<div class="empty-space"></div><div class="empty-space"></div><div class="empty-space"></div>');
		})

		wasInited = true;
	}

	window.initClankyFlexbox = initClankyFlexbox;

})();
