(function() {


	function init() {

		$(".branch-google-map").each(function() {

			try {
				var pos = JSON.parse($(this).attr("data-marker-position"))
			} catch (e) {
				return;
			}

			var googleMap = new google.maps.Map(
				this, {
					zoom: 15,
					center: pos,
					scrollwheel: false
				}
			);

			var marker = new google.maps.Marker({
				position: pos,
				icon: "imgs/map-marker.png"
			});

			marker.setMap(googleMap);

		});

	}

	window.initBranchesPageMaps = init;

})();
