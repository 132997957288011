(function() {

	var $cont;
	var googleMap;
	var mapMarkers = {};
	var $map;

	var worldIcon = {
		url: 'imgs/map-marker-blue.png',
		anchor: {
			x: 33,
			y: 33
		}
	}

	var branchIcon = {
		url: 'imgs/map-marker.png'
	}

	var branchIconBlue = {
		url: 'imgs/map-marker-2.png'
	}

	var branchIconBlueHover = {
		url: 'imgs/map-marker-2-hover.png'
	}

	var cityBounds = {
		/*
		'city-brno': {
			'west': 16.575,
			'east': 16.65,
			'north': 49.232,
			'south': 49.183
		},
		'city-olomouc': {
			'west': 17.207,
			'east': 17.283,
			'north': 49.605,
			'south': 49.57
		}
		*/
	}

	var worldBounds = {
		'west': 10,
		'east': 18,
		'north': 55,
		'south': 46
	};

	var branchesData = {
		/*
		'branch-ol-trida-miru': {
			'position': {
				lat: 49.5950819,
				lng: 17.2172478
			},
			title: 'Třída Míru',
			city: 'city-olomouc'
		},
		'branch-ol-masarykova': {
			'position': {
				lat: 49.5953144,
				lng: 17.2672467
			},
			'title': 'Masarykova třída',
			'city': 'city-olomouc'
		},
		'branch-ol-schweitzerova': {
			'position': {
				lat: 49.5739886,
				lng: 17.2484172
			},
			'title': 'Schweitzerova',
			'city': 'city-olomouc'
		},
		'branch-brno-merhautova': {
			'position': {
				lat: 49.2164172,
				lng: 16.6276008
			},
			'title': 'Merhautova',
			'city': 'city-brno'
		},
		'branch-brno-videnska': {
			'position': {
				lat: 49.1858756,
				lng: 16.5945894
			},
			'title': 'VídeŘská',
			'city': 'city-brno'
		}
		*/
	}

	function goToCity(cityId) {

		$cont.removeClass("map-level-world");
		$cont.addClass("map-level-city");
		$cont.removeClass("map-level-branch");

		var $activeCity = $cont.find('#' + cityId);
		$activeCity.removeClass("inactive");
		$cont.find(".map-overlay-city").not($activeCity).addClass("inactive")

		if ($(window).width() < 740) {
			google.maps.event.trigger(googleMap, "resize");
		}

		googleMap.fitBounds(getBoundsForCityZoomed(cityId));

		setMarkersMode(false);
	}

	function getBoundsForCityZoomed(cityId) {
		var boundsForLeftPart = $.extend({}, cityBounds[cityId]);

		if ($(window).width() < 740) {
			return boundsForLeftPart;
		}

		boundsForLeftPart.east += boundsForLeftPart.east - boundsForLeftPart.west;
		return boundsForLeftPart;
	}

	function goToBranch(branchId) {

		if ($cont.is(".map-level-world")) {
			googleMap.fitBounds(
				getBoundsForCityZoomed(
					branchesData[branchId].city
				)
			);
		}

		$cont.removeClass("map-level-world");
		$cont.removeClass("map-level-city");
		$cont.addClass("map-level-branch");

		if ($(window).width() < 740) {
			google.maps.event.trigger(googleMap, "resize");
		}

		var $activeBranch = $cont.find('#' + branchId);

		$activeBranch.removeClass("inactive");
		$cont.find(".map-overlay-branch").not($activeBranch).addClass("inactive")

		setMarkersMode(false, branchId);

	}

	function goToWorld() {
		$cont.addClass("map-level-world");
		$cont.removeClass("map-level-city");
		$cont.removeClass("map-level-branch");

		if ($(window).width() < 740) {
			google.maps.event.trigger(googleMap, "resize");
		}

		googleMap.fitBounds(
			worldBounds
		);

		setMarkersMode(true);

	}

	function setMarkersMode(isWorld, activeBranch) {

		if (isWorld) {
			$.each(mapMarkers, function(id, marker) {
				marker.setIcon(worldIcon);
				if (marker.xxlistener) {
					marker.xxlistener.remove();
					marker.xxlistener2.remove();
					marker.xxlistener = null;
					marker.xxlistener2 = null;
				}
			})
		} else {
			$.each(mapMarkers, function(id, marker) {
				if (id == activeBranch) {
					marker.setIcon(branchIcon);
					if (marker.xxlistener) {
						marker.xxlistener.remove();
						marker.xxlistener2.remove();
						marker.xxlistener = null;
						marker.xxlistener2 = null;
					}					
				} else {
					marker.setIcon(branchIconBlue);
					if (marker.xxlistener) {
						marker.xxlistener.remove();
						marker.xxlistener2.remove();
						marker.xxlistener = null;
						marker.xxlistener2 = null;
					}
					marker.xxlistener = google.maps.event.addListener(marker, 'mouseover', function() {
						marker.setIcon(branchIconBlueHover);
					})
					marker.xxlistener2 = google.maps.event.addListener(marker, 'mouseout', function() {
						marker.setIcon(branchIconBlue);
					})
				}
			})
		}

	}

	function createMap() {

		googleMap = new google.maps.Map(
			$("#branches-map")[0], {
				zoom: 10,
				center: {lat: 49.43, lng: 17.07},
				scrollwheel: false,
				streetViewControl: false,
				zoomControl: true,
				zoomControlOptions: {
					position: google.maps.ControlPosition.LEFT_BOTTOM
				},
				scaleControl: true,
				fullscreenControl: false,
				mapTypeControl: true,
				mapTypeControlOptions: {
					position: google.maps.ControlPosition.TOP_LEFT,
					style: google.maps.MapTypeControlStyle.DROPDOWN_MENU
				}

			}
		);

		$.each(branchesData, function(id, branch) {

			var marker = new google.maps.Marker({
				position: branch.position,
				title: branch.title,
				icon: worldIcon,
				cursor: 'pointer'
			});

			mapMarkers[id] = marker;

			marker.addListener("click", function() {
				goToBranch(id);
			})			

			marker.setMap(googleMap);

		})

	}

	function initData(data) {

		cityBounds = data.cityBounds;
		worldBounds = data.worldBounds;
		branchesData = data.branches;

	}

	function init() {
		$(function() {
			$cont = $("#home-map");
			$map = $("#branches-map");

			$cont.find(".map-overlay-world .city").click(function() {
				goToCity($(this).attr("href").substring(1));
				return false;
			});

			$cont.find(".map-overlay-city .close").click(function() {
				goToWorld();
				return false;
			});

			$cont.find(".map-overlay-city .branch").mouseenter(function() {
				if ($cont.is(".map-level-city")) {
					setMarkersMode(false, $(this).attr("href").substring(1));
				}
			}).mouseleave(function() {
				if ($cont.is(".map-level-city")) {
					setMarkersMode(false);
				}
			});

			$cont.find(".map-overlay-city .branch").click(function() {
				goToBranch($(this).attr("href").substring(1));
				return false;
			});

			$cont.find(".map-overlay-branch .close").click(function() {
				goToCity(
					$(this).closest("[data-city-id]").attr("data-city-id")
				);
				return false;
			});

			createMap();

			goToWorld();
		});
	}

	window.initBranchesMap = init;
	window.initBranchesMapData = initData;

	window.goToCityMap = goToCity;
	window.goToBranchMap = goToBranch;
	window.goToWorldMap = goToWorld;

})();

