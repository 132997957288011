(function($) {

	$.fn.initBottomFooter = function() {

		this.find(".text-part").addClass("hidden").waypoint(function() {

			$(this.element).removeClass("hidden");

		}, {
			offset: function() {
				var wh = window.innerHeight
				return wh - 200;
			}
		});

		this.find(".bottom-part").addClass("hidden").waypoint(function(direction) {

			$(this.element).removeClass("hidden");

		}, {
			offset: function() {

				var wh = window.innerHeight;
				var ww = window.innerWidth;

				if (wh > 650 && ww > 560) {
					return (wh - $(this.element).height() / 2);
				} else {
					return Math.round(wh * 0.7);
				}
			}
		});

		return this;

	}

})(jQuery);
