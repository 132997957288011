(function($) {

	$.fn.initNavbar = function() {

		var navbar = this;

		this.find(".mainmenu a").magicLine({
			"target": "#navbar-magic-line",
			"default": "#navbar .mainmenu a.active",
			"hiddenClass": "hidden",
			"visibleClass": "visible"
		});

		this.find('.lang-switcher .main').click(function() {
			$(this).closest('.lang-switcher').toggleClass('open');
		});

		if (this.attr("data-stick-on")) {

			var _this = this;

			$(this.attr("data-stick-on")).waypoint(function(direction) {

				if (direction == "down") {
					_this.addClass("before-appearing animated");
					window.setTimeout(function() {
						_this.addClass("sticked");
						_this.removeClass("before-appearing");
					}, 250);
				} else {
					_this.addClass("before-appearing");
					window.setTimeout(function() {
						_this.removeClass("sticked");
						_this.removeClass("before-appearing");
					}, 500);

				}

			}, {
				offset: "-20%"
			});

		}

		if (this.attr("data-sm-menu")) {

			$(this.attr("data-sm-menu")).click(function() {
				$(navbar).toggleClass("expanded");
				return false;
			});

		}

		return this;

	}

})(jQuery);
