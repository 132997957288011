(function($) {

	$.fn.initFooterMap = function(options) {

		if (!options) {
			options = {};
		}

		if (typeof options.changeColorScheme === "undefined") {
			options.changeColorScheme = true;
		}

		var addressText = this.attr("data-address");
		var coords = this.attr("data-position").split(",");

		// https://snazzymaps.com/style/132/light-gray
		var mapStyle = [{"featureType":"water","elementType":"geometry.fill","stylers":[{"color":"#d3d3d3"}]},{"featureType":"transit","stylers":[{"color":"#808080"},{"visibility":"off"}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"visibility":"on"},{"color":"#b3b3b3"}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffffff"}]},{"featureType":"road.local","elementType":"geometry.fill","stylers":[{"visibility":"on"},{"color":"#ffffff"},{"weight":1.8}]},{"featureType":"road.local","elementType":"geometry.stroke","stylers":[{"color":"#d7d7d7"}]},{"featureType":"poi","elementType":"geometry.fill","stylers":[{"visibility":"on"},{"color":"#ebebeb"}]},{"featureType":"administrative","elementType":"geometry","stylers":[{"color":"#a7a7a7"}]},{"featureType":"road.arterial","elementType":"geometry.fill","stylers":[{"color":"#ffffff"}]},{"featureType":"road.arterial","elementType":"geometry.fill","stylers":[{"color":"#ffffff"}]},{"featureType":"landscape","elementType":"geometry.fill","stylers":[{"visibility":"on"},{"color":"#efefef"}]},{"featureType":"road","elementType":"labels.text.fill","stylers":[{"color":"#696969"}]},{"featureType":"administrative","elementType":"labels.text.fill","stylers":[{"visibility":"on"},{"color":"#737373"}]},{"featureType":"poi","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"poi","elementType":"labels","stylers":[{"visibility":"off"}]},{"featureType":"road.arterial","elementType":"geometry.stroke","stylers":[{"color":"#d6d6d6"}]},{"featureType":"road","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{},{"featureType":"poi","elementType":"geometry.fill","stylers":[{"color":"#dadada"}]}];

		function attachGoogleMapsLib(callback) {
			if (window.google && window.google.map) {
				callback();
				return;
			}


			var el = document.createElement("script");
			$(el).on("load", function() {
				callback();
			});
			el.type = "text/javascript";
			el.src = "https://maps.googleapis.com/maps/api/js?key=";
			window.document.head.appendChild(el);

		}

		var map;

		function createMap(targetDiv) {

			if (map) {
				return;
			}

			var positionOfTarget = new google.maps.LatLng(coords[0], coords[1]);

			mapOptions = {
				center: positionOfTarget,
				zoom: 14,
				scrollwheel: false,
				//styles: mapStyle
			};

			if (options.changeColorScheme) {
				mapOptions.styles = mapStyle;
			}

			map = new google.maps.Map(targetDiv, mapOptions);

			var marker = new google.maps.Marker({
				position: positionOfTarget,
				title: addressText,
				animation: google.maps.Animation.DROP,
				icon: {
					url: "imgs/map-marker.png",
					scaledSize: new google.maps.Size(Math.round(67 * 2/3), Math.round(107 * 2/3))
				}
			});

			marker.setMap(map);

		}

		function removeMapStyling() {
			if (!map) {
				return;
			}

			map.setOptions({
				styles: {}
			});
		}

		this.waypoint(function() {

			var waypoint = this;

			attachGoogleMapsLib(function() {
				createMap(waypoint.element);
				if ($(waypoint.element).attr("data-remove-styles")) {
					$($(waypoint.element).attr("data-remove-styles")).click(function() {
						removeMapStyling();
						$(this).remove();
						return false;
					});
				}
			});

			this.destroy();

		}, {
			offset: "100%"
		});

		return this;

	}

})(jQuery);


